<!--
 * @Author: zhangjiaheng
 * @Date: 2020-06-28 15:25:40
 * @LastEditTime: 2020-08-06 16:45:55
 * @LastEditors: zhangjiaheng
 * @Description: 视图切换
 * @FilePath: \user\src\views\moYao\components\viewSelect.vue
-->
<template>
  <en-select
    v-model="value"
    placeholder="请选择"
    :data="options"
    @change="viewsSwitching"
    style="display: inline-block;"
  >
  </en-select>
</template>

<script>
export default {
  name: "viewSelect",
  data() {
    return {
      options: [
        { value: "/businessModel/list", label: "普通视图" },
        { value: "/moyaoResources", label: "资源视图" },
        { value: "/moyaoCalendar", label: "日历视图" },
        { value: "/moyaoGroup", label: "分组视图" }
      ],
      value: this.$route.path
    };
  },
  methods: {
    // 视图切换
    viewsSwitching() {
      if (this.value !== this.$route.fullPath) {
        if (this.value === "/businessModel/list") {
          // 业务建模列表
          const urlObj = {
            path: "/businessModel/list",
            query: {
              templateId: "142356142656356334",
              templateName: "物品申请"
            }
          };
          this.$router.push(urlObj);
        } else {
          this.$router.push({ path: this.value });
        }
      }
    }
  }
};
</script>

<style>

</style>
