<!--
 * @Author: zhangjiaheng
 * @Date: 2020-08-04 16:34:51
 * @LastEditors: zhangjiaheng
 * @LastEditTime: 2020-08-04 17:48:47
 * @Description: 没有边框的时间选择器 上一项 下一项按钮
 * @FilePath: \user\src\views\moYao\components\viewSpecialDatePicker.vue
-->
<template>
  <div class="view-special-date-picker">
    <en-icon class="special-date-picker-icon" name="fanhui" @click.native="clickIcon('per')"> </en-icon>
    <el-date-picker
      class="special-date-picker"
      :clearable="false"
      v-model="date"
      type="date"
      placeholder="选择日期"
      value-format="yyyy-MM-dd"
      format="yyyy-MM-dd"
      :editable="false"
      align="center"
      @change="change"
    >
    </el-date-picker>
    <en-icon style="transform: rotate(180deg);left: 114px;top: 19px;" class="special-date-picker-icon" name="fanhui" @click.native="clickIcon('next')"> </en-icon>
  </div>
</template>

<script>
import dayJs from "dayjs";

export default {
  name: "viewSpecialDatePicker",
  props: {
    default: {
      type: String,
      default() {
        return dayJs().format("YYYY-MM-DD");
      }
    }
  },
  data() {
    return {
      date: ""
    };
  },
  mounted() {
    this.date = this.default;
  },
  methods: {
    change() {
      this.$emit("change", this.date);
    },
    clickIcon(flag) {
      if (flag === "per") {
        this.date = dayJs(new Date(this.date).getTime() - 86400000).format("YYYY-MM-DD");
      } else {
        this.date = dayJs(new Date(this.date).getTime() + 86400000).format("YYYY-MM-DD");
      }
      this.change();
    }
  }

};
</script>

<style lang="scss" scoped>
.view-special-date-picker {
  position: relative;
  width: 104px;
  height: 32px;
}
.special-date-picker {
  background: transparent !important;
  padding: 0 !important;
  width: 80px !important;
  text-align: center !important;
  /deep/.el-input__prefix {
    display: none !important;
  }
  /deep/.el-input__inner {
    cursor: default !important;
    padding: 0 !important;
    background: transparent !important;
    border: none !important;
  }
}
.special-date-picker-icon {
  width: 12px;
  height: 12px;
}
</style>
