/*
 * @Author: your name
 * @Date: 2020-06-28 11:38:22
 * @LastEditTime: 2020-06-29 14:48:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user\src\api\moyao\index.js
 */
import Moyao from "./moyao";

export const moyaoService = new Moyao();
