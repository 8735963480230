<template>
  <div class="group">
    <div v-for="(item, k) in data" :key="k" class="mod">
      <div class="head">
        <div class="col-tit">
          <span class="txts">{{ item.groupValName }}</span>
        </div>
      </div>
      <div class="cardlis">
        <!-- <draggable> -->
          <div draggable="true" v-for="(item1, k1) in item.groupData" :key="k1" class="card" :class="setClass(item1)">
            <div class="col-names">
              <span class="txts">{{ item1.name }}</span>
            </div>
            <div class="col-times">{{ (item1.availableTime || "").replace(",", "-") }}</div>
            <div v-if="resources === 'a'" class="col-local">{{ "位置：" + (item1.resourceName || "") }}</div>
            <div v-if="resources === 'a'" class="col-state">{{ goodsStatus[item1.goodsStatus] }}</div>
            <!-- 章桶显示的状态 -->
            <div v-if="resources === 'b'" class="col-state">{{ ztStatus[item1.ztStatus] }}</div>
          </div>
        <!-- </draggable> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    resources: {
      type: String,
      default: "a"
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      // 物品
      goodsStatus: {
        "": "",
        1: "新增中",
        2: "新增失败",
        3: "在柜",
        4: "离柜"
      },
      goodsStatusColor: {
        "": "",
        1: "",
        2: "",
        3: "#179eda",
        4: "#e84a56"
      },
      // 特殊class 处理
      goodsStatusClass: {
        a: {
          "": "",
          1: "",
          2: "",
          3: "in",
          4: "outside"
        },
        b: {
          "": "",
          1: "",
          2: "in",
          3: "outside"
        }
      },
      // 章桶
      ztStatus: {
        "": "",
        1: "未激活",
        2: "空闲",
        3: "占用"
      }
    };
  },
  watch: {
    resources() {
      console.log(1);
    }
  },
  methods: {
    setClass(item1) {
      let str = "";
      if (this.resources === "a") {
        str = this.goodsStatusClass[this.resources][item1.goodsStatus];
      } else {
        str = this.goodsStatusClass[this.resources][item1.ztStatus];
      }
      return str;
    }
  }
};
</script>

<style lang="scss" scoped>
.group {
  padding: 8px 0;
  height: 100%;
  overflow: hidden;
  overflow-x: auto;
  display: flex;
  .mod {
    width: 296px;
    background: #fff;
    margin: 0 0 0 8px;
    border-radius: 5px;
    height: 100%;
    cursor: default;
    flex-shrink: 0;
    border: 1px solid #fff;
    display: flex;
    flex-direction: column;
    .head {
      height: 50px;
      border-bottom: 1px solid #f3f3f3;
      padding: 10px 10px;
      zoom: 1;
      position: relative;
      .col-tit {
        line-height: 30px;
        color: #333;
        float: left;
        font-weight: bold;
        font-size: 12px;
        font-family: "PingFangSC-Light", "Hiragino Sans GB", Microsoft YaHei Light, Microsoft YaHei, Arial, sans-serif;
      }
    }
    .cardlis {
      padding: 10px;
      overflow: auto;
      .card {
        border: 1px solid #f3f3f3;
        margin-bottom: 10px;
        // border-radius: 5px;
        padding: 10px 10px 10px 12px;
        position: relative;
        background: #fff;
        zoom: 1;
        line-height: 24px;
        font-size: 12px;
        color: rgba(96, 96, 96, 1);
        .col-names {
          line-height: 24px;
          height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          width: 50%;
          float: left;
          font-weight: 400;
          text-align: left;
        }
        .col-times {
          float: right;
          text-align: right;
          line-height: 24px;
          height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          width: 50%;
          font-weight: 400;
        }
        .col-local {
          float: left;
          line-height: 24px;
          height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          width: 50%;
          font-weight: 400;
          text-align: left;
        }
        .col-state {
          float: right;
          text-align: right;
          line-height: 24px;
          height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          width: 50%;
          font-weight: 400;
        }
      }
      .card.in {
        background: #ecf4fc;
        color: #3e90fe;
        border: 1px solid rgba(70, 148, 223, 0.2);
        border-left: 2px solid rgba(70, 148, 223, 1);
      }
      .card.outside {
        background: #fcecec;
        color: #f76b6b;
        border: 1px solid rgba(245, 70, 70, 0.2);
        border-left: 2px solid rgba(247, 107, 107, 1);
      }
      .card:last-child {
        margin-bottom: 0;
      }
      // .card::before {
      //   position: absolute;
      //   top: 17px;
      //   left: 10px;
      //   width: 5px;
      //   height: 5px;
      //   border-radius: 50%;
      //   display: inline-block;
      //   float: left;
      //   margin-right: 8px;
      //   background: #179eda;
      //   content: "";
      // }
      //  单个 清楚浮动 用来撑开父级
      .card::after {
        content: "";
        display: block;
        clear: both;
      }
    }
  }
}
.mod:hover {
  border: 1px solid #4593de;
}
</style>
