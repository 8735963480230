/*
 * @Author: zhangjiaheng
 * @Date: 2020-06-19 16:07:53
 * @LastEditTime: 2020-08-05 09:41:41
 * @LastEditors: zhangjiaheng
 * @Description: 河洛api
 * @FilePath: \user\src\api\moyao\moyao.js
 */
import BaseService from "../BaseService";

class Moyao extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  // 分组视图 start
  /**
   * 查询 魔钥物品申请 分组视图
   * @param params 参数
   * @returns {*}
   */
  mdQueryResourceGroup(param) {
    const { http } = this;
    return http.post("mdQueryResourceGroup", param);
  }

  /**
   * 查询 魔钥物品申请 章桶 分组视图
   * @param params 参数
   * @returns {*}
   */
  mdQueryZtResourceGroup(param) {
    const { http } = this;
    return http.post("mdQueryZtResourceGroup", param);
  }
  // 分组视图 end

  // 日历视图 start
  /**
   * 查询 魔钥物品申请 日历视图
   * @param params 参数
   * @returns {*}
   */
  mdQueryResourceCalendar(param) {
    const { http } = this;
    return http.post("mdQueryResourceCalendar", param);
  }
  // 日历视图 end

  // 资源视图 start
  /**
   * 查询 资源视图 数据
   * @param params 参数
   * @returns {*}
   */
  mdQueryResourceReport(param) {
    const { http } = this;
    return http.post("mdQueryResourceReport", param);
  }

  /**
   * 查询 章桶 资源视图 数据
   * @param params 参数
   * @returns {*}
   */
  mdQueryZtResourceReport(param) {
    const { http } = this;
    return http.post("mdQueryZtResourceReport", param);
  }

  /**
   * 查询 资源视图 我的申请数据
   * @param params 参数
   * @returns {*}
   */
  myMyApplyList(param) {
    const { http } = this;
    return http.post("myMyApplyList", param);
  }

  // 资源视图 end
}

export default Moyao;
