var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "group" },
    _vm._l(_vm.data, function (item, k) {
      return _c("div", { key: k, staticClass: "mod" }, [
        _c("div", { staticClass: "head" }, [
          _c("div", { staticClass: "col-tit" }, [
            _c("span", { staticClass: "txts" }, [
              _vm._v(_vm._s(item.groupValName)),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "cardlis" },
          _vm._l(item.groupData, function (item1, k1) {
            return _c(
              "div",
              {
                key: k1,
                staticClass: "card",
                class: _vm.setClass(item1),
                attrs: { draggable: "true" },
              },
              [
                _c("div", { staticClass: "col-names" }, [
                  _c("span", { staticClass: "txts" }, [
                    _vm._v(_vm._s(item1.name)),
                  ]),
                ]),
                _c("div", { staticClass: "col-times" }, [
                  _vm._v(_vm._s((item1.availableTime || "").replace(",", "-"))),
                ]),
                _vm.resources === "a"
                  ? _c("div", { staticClass: "col-local" }, [
                      _vm._v(_vm._s("位置：" + (item1.resourceName || ""))),
                    ])
                  : _vm._e(),
                _vm.resources === "a"
                  ? _c("div", { staticClass: "col-state" }, [
                      _vm._v(_vm._s(_vm.goodsStatus[item1.goodsStatus])),
                    ])
                  : _vm._e(),
                _vm.resources === "b"
                  ? _c("div", { staticClass: "col-state" }, [
                      _vm._v(_vm._s(_vm.ztStatus[item1.ztStatus])),
                    ])
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }