<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-13 15:45:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-09 10:48:34
 * @Description: 魔钥 资源视图
 * @FilePath: \user\src\views\moYao\moyaoResources.vue
-->
<template>
  <div class="box">
    <div class="center">
      <div class="topbar">
        <div class="pull-left">
          <en-icon v-if="false" name="fanhui" size="small" style="color:#A9B5C6;cursor: pointer;vertical-align: middle;"> </en-icon>
          <span class="col-names" style="margin-right:12px;font-weight: bold;">物品申请</span>
          <viewsSelect></viewsSelect>
          <!-- <span class="col-names" style="margin-right:10px;margin-left:10px;">查询日期</span>
          <el-date-picker v-model="date" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" format="yyyy-MM-dd" @change="mdQueryResourceReport"> </el-date-picker> -->
          <span class="col-names" style="margin-right:12px;margin-left:12px;font-size:12px">资源类型</span>
          <en-select v-model="resources" placeholder="请选择" :data="resourcesOptions" @change="changeResources" style="display: inline-block;"> </en-select>
        </div>
        <div class="pull-right">
          <viewAddBusinessModel :templateId="templateId" businessType="5"></viewAddBusinessModel>
        </div>
      </div>
      <!-- 中心区域 -->
      <div class="centbar">
        <div class="lisfloor">
          <div class="nav">
            <!-- 图例 -->
            <viewSpecialDatePicker style="position: absolute; top: 6px; left: 20px;z-index: 99;" :default="date" @change="changeDate"></viewSpecialDatePicker>
            <en-tabs :list="tabList" class="en-tabs" type="white" v-model="tabID" @change="changeTabs" style="font-size:12px;"> </en-tabs>
            <div v-show="tabID === 'all'" class="pull-right">
              <i style="background:#3e90fe;"></i>
              <span>我的申请</span>
              <i style="background:#F76B6B;"></i>
              <span>他人申请</span>
              <i style="background:#B9C4D2;"></i>
              <span>在柜</span>
            </div>
          </div>
          <!-- 全部申请 图形区域 -->
          <en-result v-if="noData" type="NoData"></en-result>
          <div v-if="!noData" class="lis" v-show="tabID === 'all'" v-en-loading="loading">
            <div v-for="(item, k) in data" :key="k" class="mod">
              <div :id="'main' + item.id" class="main" style="width:340px;height:340px;"></div>
              <div class="tit">
                <span class="txts" :title="item.name">{{ item.name }}</span>
              </div>
              <div class="scale" v-show="item.useLimit !== '1'">
                <span class="txts txts-top">{{ item.res ? item.res.top : "1" }}</span>
                <span class="txts txts-right">{{ item.res ? item.res.right : "2" }}</span>
                <span class="txts txts-bottom">{{ item.res ? item.res.bottom : "3" }}</span>
                <span class="txts txts-left">{{ item.res ? item.res.left : "4" }}</span>
              </div>
            </div>
          </div>
          <!-- 我的申请 -->
          <div v-if="!noData" class="tempslis" v-show="tabID === 'my'">
            <div class="temps" v-for="(item, k) in temps" :key="k">
              <div class="row-icons">
                <div style="line-height:44px;background:#19aced;vertical-align: middle;border-radius: 50%;width:34px;height:34px;display: flex;justify-content: center;align-items: center;">
                  <en-icon name="moban-yewujianmo32" size="24" style="color:#fff;"> </en-icon>
                </div>
              </div>
              <div class="row-main">
                <p class="col-tits">{{ item.name }}</p>
                <p class="col-local">位置：{{ item.resourceName || "" }}</p>
              </div>
              <div class="row-side">
                <p class="col-times">
                  {{ (item.requestTime || "").substring(0, 10) }}
                </p>
                <p class="col-state">
                  <el-tag style="height: 24px;line-height: 24px;border:none;border-radius:2px;" :style="'color:#4693DF;'">
                    {{ item.billStatusName || "" }}
                  </el-tag>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayJs from "dayjs";
import { moyaoService } from "@/api/moyao/index.js";
import viewsSelect from "./components/viewSelect.vue";
import viewSpecialDatePicker from "./components/viewSpecialDatePicker.vue";
import viewAddBusinessModel from "./components/viewAddBusinessModel.vue";

export default {
  name: "moyaoResources",
  components: {
    viewsSelect,
    viewSpecialDatePicker,
    viewAddBusinessModel
  },
  data() {
    return {
      // 默认分组时间 获取今天的 日期
      date: dayJs().format("YYYY-MM-DD"),
      // 资源数据
      data: [],
      // 有无数据的标识，默认false不显示
      noData: false,
      // 全部申请 和 我的申请 切换
      tabID: "all",
      xData: [
        {
          name: "",
          data: 1
        }
      ],
      temps: [],
      tabList: [
        {
          id: "all",
          name: "全部申请"
        },
        {
          id: "my",
          name: "我的申请"
        }
      ],
      // 我的申请 他人申请 在柜的颜色
      graphColorArr: ["#3e90fe", "#f76b6b", "#b9c4d2"],
      loading: false,
      resourcesOptions: [
        { value: "a", label: "智能柜" },
        { value: "b", label: "章桶" }
      ],
      // 资源类型
      resources: "a",
      templateId: "142356142656356334"
    };
  },
  mounted() {
    // 全部申请
    this.mdQueryResourceReport();
  },
  methods: {
    // 时间变化
    changeDate(date) {
      this.date = date;
      this.mdQueryResourceReport();
    },
    changeResources() {
      this.mdQueryResourceReport();
    },
    // 获取 资源视图数据
    async mdQueryResourceReport() {
      const param = {
        templateId: "142354345348863344",
        applyType: this.tabID,
        queryDate: this.date
      };
      let rspData = [];
      try {
        this.loading = true;
        if (this.resources === "a") {
          //  智能柜
          rspData = (await moyaoService.mdQueryResourceReport(param)) || [];
        } else {
          // 章桶
          rspData = (await moyaoService.mdQueryZtResourceReport(param)) || [];
        }
      } catch (error) {
        rspData = [];
      }
      // 跨天数据清洗
      this.resetData(rspData);
      this.handle(rspData);
      this.loading = false;
      this.data = rspData;
      console.log(this.data);
      const This = this;
      if (this.data.length) {
        // dom 渲染完毕 处理图形
        this.noData = false;
        this.$nextTick(() => {
          this.data.forEach((item) => {
            const dom = document.getElementById(`main${item.id}`);
            const myChart = This.$charts.init(dom);
            myChart.clear();
            myChart.setOption(item.option);
          });
        });
      } else {
        this.noData = true;
      }
    },
    // 跨天数据清洗
    resetData(rspData) {
      console.log(rspData);
      const This = this;
      rspData.forEach((item) => {
        const availableTime = (item.availableTime || "00:00,23:59").split(",");
        item.data.forEach((item1) => {
          const arr = item1.time.split(",");
          const arr1 = (arr[0] || "").split(" ");
          const arr2 = (arr[1] || "").split(" ");
          let stD = arr1[0]; // 开始的日期
          let stTime = arr1[1]; // 开始的时间
          let etD = arr2[0]; // 结束的日期
          let etTime = arr2[1]; // 结束的时间
          if (stD !== etD) {
            // 跨天
            if (stD !== This.date) {
              // 如果开始日期不等于 当前日期
              stD = This.date;
              stTime = availableTime[0];
            }
            if (etD !== This.date) {
              // 如果结束日期不等于 当前日期
              etD = This.date;
              etTime = availableTime[1];
            }
            item1.time = `${stD} ${stTime},${etD} ${etTime}`;
          }
        });
      });
    },
    // 处理返回数据
    handle(rspData) {
      // 开始时间 结束时间分离
      rspData.forEach((element) => {
        if (element.availableTime) {
          const time = element.availableTime.split(",");
          element.st = new Date(`${this.date} ${time[0]}`).getTime(); // 开始时间 时间戳
          element.et = new Date(`${this.date} ${time[1]}`).getTime(); // 结束时间 时间戳
          element.res = {};
          const four = (element.et - element.st) / 4;
          element.res.top = dayJs(element.st).format("HH:mm");
          element.res.right = dayJs(element.st + four).format("HH:mm");
          element.res.bottom = dayJs(element.st + four * 2).format("HH:mm");
          element.res.left = dayJs(element.st + four * 3).format("HH:mm");
        }
        element.data.forEach((element2) => {
          const time1 = element2.time.split(",");
          element2.st = new Date(time1[0]).getTime(); // 开始时间 时间戳
          element2.et = new Date(time1[1]).getTime(); // 结束时间 时间戳
        });
        // 按照申请的开始时间排序排序
        element.data.sort((a, b) => a.st - b.st);
      });
      // new 创建一个数组，把的所有数据 开始时间和结束 分开压入数组中
      // 然后排序，按照时间排序 开始时间在结束时间前面排序
      // 合并时间
      rspData.forEach((element) => {
        element.dataList = [];
        element.data.forEach((element2) => {
          const st = {
            time: element2.st,
            flg: 1,
            item: element2
          };
          const et = {
            time: element2.et,
            flg: 2,
            item: element2
          };
          element.dataList.push(st);
          element.dataList.push(et);
        });
        // 排序
        element.dataList.sort((a, b) => a.flg - b.flg);
        // element.dataList.sort((a, b) => a.time - b.time);
        element.dataList.sort((a, b) => (a.time - b.time === 0 ? b.flg - a.flg : a.time - b.time));
        element.mergeList = [];
        // 循环数据
        let flg = 1;
        let num = 0; // 合并基数
        element.dataList.forEach((element1) => {
          if (element1.flg === 1 && flg === 1 && num === 0) {
            // 是开始时间 并且当前标识也是开始时间 合并基数为0
            // 复制数据
            const copy = JSON.parse(JSON.stringify(element1));
            copy.children = [element1.item];
            copy.st = element1.time;
            element.mergeList.push(copy);
            // 标识变为2 合并基数+ 1
            flg = 2;
            num += 1;
          } else if (element1.flg === 1 && flg === 2) {
            // 是开始时间 但是 标识是结束时间 是需要合并的时间段
            // 数据需要要入到最后一个的children里面
            element.mergeList[element.mergeList.length - 1].children.push(element1.item);
            // 合并基数 + 1
            num += 1;
          } else if (element1.flg === 2 && flg === 2 && num === 1) {
            // 是结束时间 标识是结束时间 合并基数为1 是结尾数据
            // 赋值结束时间
            element.mergeList[element.mergeList.length - 1].et = element1.time;
            // 我的申请 他人申请区别
            element.mergeList[element.mergeList.length - 1].mineFlag = element1.item.mineFlag;
            flg = 1;
            num -= 1;
          } else if (element1.flg === 2 && flg === 2 && num > 1) {
            // 是结束时间 标识是结束时间 合并基数大于1  是中间部位
            num -= 1;
          }
        });
      });
      // 生成图形用的数据list 用时间差来计算比例
      rspData.forEach((element) => {
        let option = {};
        if (element.useLimit !== "1") {
          // 有使用限制 按照处理
          const list = [];
          let st = element.st;
          let et = 0;
          // const graphColorArr = typeof element.graphColorArr === "string" ? JSON.parse(element.graphColorArr) : element.graphColorArr;
          element.mergeList.forEach((element2) => {
            const val = element2.st - st;
            if (val > 0) {
              list.push({
                name: "可用",
                value: val,
                children: [],
                flg: "可用",
                itemStyle: {
                  color: this.graphColorArr[2],
                  emphasis: { color: this.graphColorArr[2] }
                },
                tooltip: {
                  formatter: (a) => {
                  // console.log(a, b, c);
                    const st = dayJs(a.data.st).format("HH:mm");
                    const et = dayJs(a.data.et).format("HH:mm");
                    return `${st}-${et}`;
                  }
                },
                st,
                et: element2.st
              });
            }
            const val1 = element2.et - element2.st;
            if (val1 > 0) {
              list.push({
                name: "当前时间已被占用",
                value: val1,
                children: element2.children,
                flg: "已使用",
                itemStyle: {
                  color: String(element2.mineFlag) === "0" ? this.graphColorArr[0] : this.graphColorArr[1],
                  emphasis: { color: String(element2.mineFlag) === "0" ? this.graphColorArr[0] : this.graphColorArr[1] }
                },
                tooltip: {
                  formatter: (a) => {
                  // console.log(a, b, c);
                    const st = dayJs(a.data.st).format("HH:mm");
                    const et = dayJs(a.data.et).format("HH:mm");
                    return `${st}-${et}<br>${a.name}`;
                  }
                },
                st: element2.st,
                et: element2.et
              });
            }
            st = element2.et;
            et = element2.et;
          });
          // 最后剩余时间
          const val = element.et - et;
          if (val > 0) {
            list.push({
              name: "可用",
              value: val,
              children: [],
              flg: "可用",
              itemStyle: {
                color: this.graphColorArr[2],
                emphasis: { color: this.graphColorArr[2] }
              },
              tooltip: {
                formatter: (a) => {
                // console.log(a, b, c);
                  const st = dayJs(a.data.st).format("HH:mm");
                  const et = dayJs(a.data.et).format("HH:mm");
                  return `${st}-${et}`;
                }
              },
              st: et === 0 ? element.st : et, // 如果没有使用过特殊处理
              et: element.et
            });
          }
          element.list = list;
          option = {
            tooltip: {
              trigger: "item",
              formatter: "{b}"
            },
            series: [
              {
                name: "",
                type: "pie",
                radius: ["60%", "75%"],
                avoidLabelOverlap: false,
                legendHoverLink: false,
                // startAngle: 180,
                label: {
                  normal: {
                    show: false
                  }
                },
                data: list
              }
            ]
          };
        } else if (element.goodsStatus !== "3") {
          // 离柜
          let mineFlag = 2;
          try {
            mineFlag = element.data[0].mineFlag === 0 ? 0 : 1;
          } catch (error) {
            mineFlag = 2;
          }
          option = {
            tooltip: {
              trigger: "item",
              formatter: "{b}"
            },
            series: [
              {
                name: "",
                type: "pie",
                radius: ["60%", "75%"],
                avoidLabelOverlap: false,
                legendHoverLink: false,
                // startAngle: 180,
                label: {
                  normal: {
                    show: false
                  }
                },
                data: [{
                  name: "可用",
                  value: element.et - element.st,
                  children: [],
                  flg: "可用",
                  itemStyle: {
                    color: this.graphColorArr[mineFlag],
                    emphasis: { color: this.graphColorArr[mineFlag] }
                  },
                  tooltip: {
                    formatter: (a) => {
                      // console.log(a, b, c);
                      const st = dayJs(a.data.st).format("HH:mm");
                      const et = dayJs(a.data.et).format("HH:mm");
                      return `${st}-${et}`;
                    }
                  },
                  st: element.st, // 如果没有使用过特殊处理
                  et: element.et
                }]
              }
            ]
          };
        } else {
          // 在柜
          option = {
            tooltip: {
              trigger: "item",
              formatter: "{b}"
            },
            series: [
              {
                name: "",
                type: "pie",
                radius: ["60%", "75%"],
                avoidLabelOverlap: false,
                legendHoverLink: false,
                // startAngle: 180,
                label: {
                  normal: {
                    show: false
                  }
                },
                data: [{
                  name: "可用",
                  value: element.et - element.st,
                  children: [],
                  flg: "可用",
                  itemStyle: {
                    color: this.graphColorArr[2],
                    emphasis: { color: this.graphColorArr[2] }
                  },
                  tooltip: {
                    formatter: (a) => {
                      // console.log(a, b, c);
                      const st = dayJs(a.data.st).format("HH:mm");
                      const et = dayJs(a.data.et).format("HH:mm");
                      return `${st}-${et}`;
                    }
                  },
                  st: element.st, // 如果没有使用过特殊处理
                  et: element.et
                }]
              }
            ]
          };
        }
        element.option = option;
      });
    },
    // 获取 我的申请
    async myMyApplyList() {
      const rspData = await moyaoService.myMyApplyList();
      this.temps = rspData.records;
      if (this.temps.length) {
        this.noData = false;
      } else {
        this.noData = true;
      }
    },
    changeTabs() {
      if (this.tabID === "all") {
        this.mdQueryResourceReport();
      } else {
        this.myMyApplyList();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// 外层
.box {
  font-family: Microsoft YaHei, Arial, sans-serif;
  margin: 0;
  height: 100%;
  padding: 0;
  overflow: hidden;
  background: rgba(245, 247, 250, 1);
  //内部容器
  .center {
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    // overflow-y: auto;
    background: #fff;
    .topbar {
      padding: 0px 20px;
      background: #fff;
      min-height: 50px;
      zoom: 1;
      position: relative;
      border-bottom: 1px solid #e8ecf2;
      border-radius: 5px 5px 0 0;
      //   vertical-align: middle;
      //   line-height: 50px;
      .pull-left {
        float: left;
        .col-names {
          vertical-align: middle;
          font-size: 14px;

          color: rgba(51, 51, 51, 1);
          line-height: 50px;
        }
      }
      .pull-right {
        float: right;
        display: flex;
        height: 50px;
        align-items: center;
      }
    }
    .centbar {
      height: calc(100% - 51px);
      .lisfloor {
        height: 100%;
        .nav {
          position: relative;
          min-height: 42px;
          background-color: #f5f7fa;
          &/deep/.en-tabs {
            background-color: #f5f7fa;
            width: 200px;
            position: absolute;
            z-index: 99;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            height: 42px;
          }
          &/deep/.en-tabs-list {
            margin: 0 auto;
            background-color: #f5f7fa;
            display: flex;
            justify-content: center;
          }
          //  右侧图例
          .pull-right {
            position: absolute;
            right: 20px;
            top: 0px;
            height: 42px;
            line-height: 42px;
            i {
              width: 14px;
              height: 14px;
              display: inline-block;
              vertical-align: middle;
              margin-right: 5px;
              margin-left: 10px;
              border-radius: 2px;
            }
            span {
              font-family: Microsoft YaHei, Arial, sans-serif;
              font-size: 12px;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
        // 图形区域
        .lis {
          height: calc(100% - 42px);
          padding: 20px 20px 20px 20px;
          text-align: center;
          font-size: 0;
          background: #fff;
          overflow: auto;
          .mod {
            position: relative;
            width: 340px;
            flex-shrink: 0;
            flex-grow: 0;
            // max-width: 30%;
            display: inline-block;
            vertical-align: top;
            height: 340px;
            margin: 0 50px;
            // echarts 容器
            .main {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 10;
            }
            .tit {
              position: absolute;
              top: 50%;
              width: 60%;
              left: 20%;
              text-align: center;
              margin-top: -16px;
              height: 32px;
              line-height: 32px;
              font-size: 16px;
              color: #179eda;
              font-family: "PingFangSC-Light", "Hiragino Sans GB", Microsoft YaHei Light, Microsoft YaHei, Arial, sans-serif;
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: block;
            }
            .scale {
              position: relative;
              height: 100%;
              width: 100%;
              z-index: 1;
              .txts {
                font-size: 14px;
                color: #606060;
                height: 30px;
                line-height: 30px;
              }
              .txts-top {
                position: absolute;
                top: 5%;
                left: 50%;
                margin-top: -8px;
                width: 40px;
                margin-left: -20px;
                text-align: center;
              }
              .txts-right {
                position: absolute;
                top: 50%;
                margin-top: -8px;
                right: 0%;
              }
              .txts-bottom {
                position: absolute;
                bottom: 5%;
                margin-bottom: -8px;
                left: 50%;
                width: 40px;
                margin-left: -20px;
                text-align: center;
              }
              .txts-left {
                position: absolute;
                top: 50%;
                left: 0%;
                margin-top: -8px;
              }
            }
          }
        }
        // 我的申请
        .tempslis {
          background: #fff;
          height: 100%;
          border-top: 1px solid #f3f3f3;
          .temps {
            border-bottom: 1px solid #f3f3f3;
            zoom: 1;
            padding: 12px 20px;
            margin: 0;
            .row-icons {
              float: left;
              margin-right: 10px;
            }
            .row-main {
              float: left;
              font-weight: normal;
              text-align: left;
              p {
                margin: 0;
                line-height: 22px;
              }
              .col-tits {
                color: #333;
                font-size: 14px;
              }
              .col-local {
                color: #606060;
                font-size: 12px;
              }
            }
            .row-side {
              font-size: 12px;
              float: right;
              text-align: right;
              p {
                margin: 0;
                line-height: 22px;
                text-align: right;
              }
              .col-times {
                color: #606060;
              }
            }
          }
          .temps::after {
            content: "";
            display: block;
            clear: both;
          }
        }
      }
    }
  }
}
</style>
