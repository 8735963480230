var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    [
      _c(
        "span",
        { staticClass: "el-dropdown-link" },
        [
          _c(
            "el-button",
            {
              staticClass: "btn-add",
              attrs: { type: "primary", size: "small" },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.detailAdd.apply(null, arguments)
                },
              },
            },
            [
              _c("span", { staticClass: "iconfont addicon" }, [_vm._v("")]),
              _vm._v("添加"),
            ]
          ),
        ],
        1
      ),
      _c("el-dropdown-menu"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }