<!--
 * @Author: zhangjiaheng
 * @Date: 2020-06-28 14:57:58
 * @LastEditTime: 2021-07-06 17:25:58
 * @LastEditors: Please set LastEditors
 * @Description: 魔钥 分组视图
 * @FilePath: \user\src\views\moYao\moyaoGroup.vue
-->
<template>
  <div class="box">
    <div class="center">
      <div class="topbar">
        <div class="pull-left">
          <en-icon v-if="false" name="fanhui" size="small" style="color:#A9B5C6;cursor: pointer;vertical-align: middle;"> </en-icon>
          <span class="col-names" style="margin-right:12px;font-weight: bold;">物品申请</span>
          <viewsSelect></viewsSelect>
          <span class="col-names" style="margin-right:12px;margin-left:12px;font-size:12px">资源类型</span>
          <en-select v-model="resources" placeholder="请选择" :data="resourcesOptions" @change="changeResources" style="display: inline-block;"> </en-select>
          <span class="col-names" style="margin-right:12px;margin-left:12px;font-size:12px;">分组条件</span>
          <en-select v-model="nameVariable" placeholder="请选择" :data="options" style="display: inline-block;" @change="mdQueryResourceGroup"> </en-select>
          <span class="col-names" style="margin-right:12px;margin-left:12px;font-size:12px;">查询日期</span>
          <el-date-picker v-model="date" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" format="yyyy-MM-dd" @change="mdQueryResourceGroup"> </el-date-picker>
        </div>
        <div class="pull-right">
          <viewAddBusinessModel :templateId="templateId" businessType="5"></viewAddBusinessModel>
        </div>
      </div>
      <!-- 中心区域 -->
      <div class="centbar" v-en-loading="loading">
        <en-result v-if="data.length === 0 && !loading" type="NoData"></en-result>
        <div v-else class="lis">
          <viewsGroup :resources="resources" :data="data"></viewsGroup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayJs from "dayjs";
import { moyaoService } from "@/api/moyao/index";
import viewsGroup from "./components/viewsGroup";
import viewsSelect from "./components/viewSelect";
import viewAddBusinessModel from "./components/viewAddBusinessModel";

export default {
  components: {
    viewsGroup,
    viewsSelect,
    viewAddBusinessModel
  },
  name: "maoyaoGroup",
  data() {
    return {
      // 分组条件
      options: [
        { value: "useLimit", label: "使用限制" },
        { value: "goodsStatus", label: "状态" },
        { value: "goodsClass", label: "分类" },
        { value: "location", label: "位置" }
      ],
      // 默认分组条件
      nameVariable: "useLimit",
      // 默认分组时间 获取今天的 日期
      date: dayJs().format("YYYY-MM-DD"),
      // 分组数据
      data: [],
      resourcesOptions: [
        { value: "a", label: "智能柜" },
        { value: "b", label: "章桶" }
      ],
      // 资源类型
      resources: "a",
      loading: false,
      templateId: "142356142656356334"
    };
  },
  mounted() {
    this.mdQueryResourceGroup();
  },
  methods: {
    changeResources() {
      if (this.resources === "a") {
        this.options = [
          { value: "useLimit", label: "使用限制" },
          { value: "goodsStatus", label: "状态" },
          { value: "goodsClass", label: "分类" },
          { value: "location", label: "位置" }
        ];
        this.nameVariable = "useLimit";
      } else {
        this.options = [{ value: "ztStatus", label: "状态" }];
        this.nameVariable = "ztStatus";
      }
      this.mdQueryResourceGroup();
    },
    //   获取分组数据
    async mdQueryResourceGroup() {
      this.loading = true;
      this.data = [];
      const param = {
        templateId: "142354345348863344",
        applyType: "all",
        nameVariable: this.nameVariable,
        fieldType: "5",
        queryDate: this.date
      };
      let rspData = [];
      try {
        if (this.resources === "a") {
          rspData = await moyaoService.mdQueryResourceGroup(param) || [];
        } else {
          rspData = await moyaoService.mdQueryZtResourceGroup(param) || [];
        }
      } catch (error) {
        rspData = [];
      }
      this.data = rspData;
      console.log(this.data);
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
// 外层
.box {
  font-family: Microsoft YaHei, Arial, sans-serif;
  margin: 0;
  height: 100%;
  padding: 0;
  overflow: hidden;
  background: rgba(245, 247, 250, 1);
  //内部容器
  .center {
    height: calc(100% - 22px);
    border-radius: 5px;
    overflow: hidden;
    overflow-y: auto;
    // margin: 10px;
    // background: #fff;
    .topbar {
      padding: 0px 20px;
      background: #fff;
      min-height: 50px;
      zoom: 1;
      position: relative;
      border-bottom: 1px solid #f3f3f3;
      border-radius: 5px 5px 0 0;
      //   vertical-align: middle;
      //   line-height: 50px;
      .pull-left {
        float: left;
        .col-names {
          vertical-align: middle;
          font-size: 14px;

          color: rgba(51, 51, 51, 1);
          line-height: 50px;
        }
      }
      .pull-right {
        float: right;
        display: flex;
        height: 50px;
        align-items: center;
      }
    }
    // 中心区域
    .centbar {
      height: calc(100% - 51px);
      .lis {
        height: 100%;
        position: relative;
        background: #ecf1f7;
      }
    }
  }
}
</style>
