<!--
 * @Author: zhangjiaheng
 * @Date: 2020-08-05 09:09:57
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-07-06 17:19:16
 * @Description: 物品申请中的 右上角添加按钮 跳转到业务建模详情
 * @FilePath: \user\src\views\moYao\components\viewAddBusinessModel.vue
-->
<template>
  <el-dropdown>
    <span class="el-dropdown-link">
      <el-button class="btn-add" type="primary" size="small" @click.native.stop="detailAdd"><span class="iconfont addicon">&#xe602;</span>添加</el-button>
    </span>
    <el-dropdown-menu> </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { newEntranceRoute } from "@/components/businessDetail/helper.js";

export default {
  props: {
    templateId: {
      type: String,
      default: ""
    },
    businessType: {
      type: String,
      default: "2"
    }
  },
  methods: {
    // 添加 跳转到业务建模添加
    detailAdd() {
      const urlObj = {
        templateId: this.templateId,
        businessType: 5,
        templateName: this.templateName || this.$route.query.templateName,
        picType: this.$route.query.picType || "1",
        viewType: this.$route.query.viewType
      };
      // 魔钥的资源不需要这个
      if (this.templateId !== "142356142656356334") {
        if (this.$route.path === "/businessModel/resources") {
          urlObj.sourcetype = "list_resources";
        } else if (this.$route.path === "/businessModel/calendar") {
          urlObj.sourcetype = "list_calendar";
        } else if (this.$route.path === "/businessModel/gantt") {
          urlObj.sourcetype = "list_gantt";
        }
      }
      console.log(newEntranceRoute(urlObj));
      this.$router.push(newEntranceRoute(urlObj));
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-add {
  font-weight: bold !important;
  /deep/.el-icon-plus {
    font-weight: bold !important;
  }
}

.addicon {
  font-size: 11px;
  margin-right: 5px;
}
</style>
