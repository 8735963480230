<template>
  <!-- 魔钥 日历视图 -->
  <div class="box">
    <div class="center">
      <div class="topbar">
        <div class="pull-left">
          <en-icon v-if="false" name="fanhui" size="small" style="color:#A9B5C6;cursor: pointer;vertical-align: middle;"> </en-icon>
          <span class="col-names" style="margin-right:10px;font-weight: bold;">物品申请</span>
          <viewsSelect></viewsSelect>
        </div>
        <div class="pull-right">
          <viewAddBusinessModel :templateId="templateId" businessType="5"></viewAddBusinessModel>
        </div>
      </div>
      <!-- 中心区域 -->
      <div class="centbar">
        <!-- 图例 -->
        <en-tabs :list="tabList" type="white" v-model="tabID" @change="changeTabs" style="font-size:12px;"> </en-tabs>
        <div class="pull-right">
          <i style="background:#4593DF;"></i>
          <span>我的申请</span>
          <i v-if="tabID === 'all'" style="background:#F76B6B;"></i>
          <span v-if="tabID === 'all'">他人申请</span>
          <i style="background:#636B77;"></i>
          <span>已结束</span>
        </div>
        <viewsCalender :need-head="true" :other-month="true" :start-day="1" :data="data" @toParent="getDate"> </viewsCalender>
      </div>
    </div>
  </div>
</template>

<script>
import { moyaoService } from "@/api/moyao/index";
import viewsCalender from "./components/viewsCalender";
import viewsSelect from "./components/viewSelect";
import viewAddBusinessModel from "./components/viewAddBusinessModel";

export default {
  components: {
    viewsCalender,
    viewsSelect,
    viewAddBusinessModel
  },
  name: "moyaoCalendar",
  data() {
    return {
      // 默认分组时间 获取今天的 日期
      date: "",
      // 日历数据
      data: {},
      tabList: [
        {
          id: "all",
          name: "全部申请"
        },
        {
          id: "mine",
          name: "我的申请"
        }
      ],
      tabID: "all",
      templateId: "142356142656356334"
    };
  },
  methods: {
    // 获取当月 2018-05-01,2018-06-01,
    getMonth(dd) {
      const d = new Date(dd) || new Date();
      const m = `00${d.getMonth() + 1}`;
      const m1 = `00${d.getMonth() + 2}`;
      return `${d.getFullYear()}-${m.substr(m.length - 2)}-01,${d.getFullYear()}-${m1.substr(m1.length - 2)}-01`;
    },
    // 获取 资源视图数据
    async mdQueryResourceCalendar() {
      const param = {
        templateId: this.templateId,
        applyType: this.tabID,
        queryDateRange: this.date,
        resourceType: "1"
        // applyType: "all",
        // templateId: "142356142656356334",
        // queryDateRange: "2020-09-01,2020-10-01"
      };
      const data = await moyaoService.mdQueryResourceCalendar(param);
      this.data = {};
      data.forEach((element) => {
        this.data[element.date] = element.data;
      });
    },
    // 获取 组件返回的 日期值用来查询数据
    getDate(time) {
      this.date = this.getMonth(time);
      this.mdQueryResourceCalendar();
    },
    // 全部 我的切换
    changeTabs() {
      this.mdQueryResourceCalendar();
    }
  }
};
</script>

<style lang="scss" scoped>
// 外层
.box {
  font-family: Microsoft YaHei, Arial, sans-serif;
  margin: 0;
  height: 100%;
  padding: 0;
  overflow: hidden;
  background: rgba(245, 247, 250, 1);
  //内部容器
  .center {
    height: calc(100% - 22px);
    border-radius: 5px;
    overflow: hidden;
    overflow-y: auto;
    margin: 10px;
    background: #fff;
    .topbar {
      padding: 0 10px;
      background: #fff;
      min-height: 50px;
      zoom: 1;
      position: relative;
      border-bottom: 1px solid #f3f3f3;
      border-radius: 5px 5px 0 0;
      //   vertical-align: middle;
      //   line-height: 50px;
      .pull-left {
        float: left;
        .col-names {
          vertical-align: middle;
          font-size: 14px;

          color: rgba(51, 51, 51, 1);
          line-height: 50px;
        }
      }
      .pull-right {
        float: right;
        display: flex;
        height: 50px;
        align-items: center;
      }
    }
    .centbar {
      position: relative;
      //  右侧图例
      .pull-right {
        position: absolute;
        height: 44px;
        line-height: 44px;
        background: #f7f9f9;
        right: 4px;
        top: 1px;
        z-index: 9999;
        i {
          width: 14px;
          height: 14px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 6px;
          margin-left: 30px;
        }
        span {
          font-family: Microsoft YaHei, Arial, sans-serif;
          font-size: 12px;
          display: inline-block;
          vertical-align: middle;
        }
      }
      .en-tabs {
        font-size: 12px;
        width: 200px;
        position: absolute;
        z-index: 99;
        top: 0;
        left: 50%;
        transform: translate(-50%);
        height: 45px;
        background-color: #f7f9f9;
      }
    }
  }
}
</style>
