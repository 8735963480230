<template>
  <div class="en-date-picker">
    <div v-if="needHead" class="en-date-picker-header">
      <en-icon name="fanhui" @click.native="changeMonth(-1)"> </en-icon>
      <div class="label" v-text="currentYear + '-' + (Number(currentMonth) < 10 ? '0' + currentMonth : currentMonth)"></div>
      <en-icon name="fanhui" @click.native="changeMonth(1)"> </en-icon>
    </div>
    <div class="en-date-picker-week">
      <div v-for="(item, k) in weekArr" :key="k" class="en-date-picker-week-item">
        {{ item }}
      </div>
    </div>
    <div class="en-date-picker-body">
      <div
        v-for="(item, index) in list"
        :key="item.timeStamp + index"
        :class="{
          'other-month': item.isOtherMonth,
          'current-date': item.timeStamp === currentTimeStamp
        }"
        class="en-date-picker-item"
      >
        <div class="label">
          {{ item.date }}
        </div>
        <!-- 修改 添加展示信息区域  -->
        <div
          class="col"
          v-if="
            data[
              currentYear +
                '-' +
                (Number(!item.isOtherMonth ? currentMonth : currentMonth - 1) < 10
                  ? '0' + (!item.isOtherMonth ? currentMonth : currentMonth - 1)
                  : !item.isOtherMonth
                  ? currentMonth
                  : currentMonth - 1) +
                '-' +
                (Number(item.date) < 10 ? '0' + item.date : item.date)
            ] &&
              data[
                currentYear +
                  '-' +
                  (Number(!item.isOtherMonth ? currentMonth : currentMonth - 1) < 10
                    ? '0' + (!item.isOtherMonth ? currentMonth : currentMonth - 1)
                    : !item.isOtherMonth
                    ? currentMonth
                    : currentMonth - 1) +
                  '-' +
                  (Number(item.date) < 10 ? '0' + item.date : item.date)
              ].length > 0
          "
        >
          <el-popover
            placement="bottom-start"
            width="240"
            trigger="click"
            v-for="(item, k) in data[
              currentYear +
                '-' +
                (Number(!item.isOtherMonth ? currentMonth : currentMonth - 1) < 10
                  ? '0' + (!item.isOtherMonth ? currentMonth : currentMonth - 1)
                  : !item.isOtherMonth
                  ? currentMonth
                  : currentMonth - 1) +
                '-' +
                (Number(item.date) < 10 ? '0' + item.date : item.date)
            ]"
            :key="k"
          >
            <div class="cardinfo-alert">
              <div class="col-names">
                <span class="txts">{{ item.name }}</span>
              </div>
              <div class="col">
                <en-icon name="shijian-xuanxiang" size="14" style="color:#A9B5C6;margin-right:8px;"> </en-icon>
                {{ (item.requestTime || "").slice(0, 10) }}
              </div>
              <!-- dizhi-kongjian dizhi-kaoqin -->
              <div class="col">
                <en-icon name="dizhi-kaoqin" size="14" style="color:#A9B5C6;margin-right:8px;"> </en-icon>
                位置：{{ item.resourceName || "" }}
              </div>
              <!-- ligui -->
              <div class="col">
                <en-icon name="ligui" size="14" style="color:#A9B5C6;margin-right:8px;"> </en-icon>
                {{ goodsStatus[item.goodsStatus || ""] }}
              </div>
            </div>
            <div slot="reference" class="col-names " :class="getClass(item)">
              <div class="txts-div">
                <span class="txts-event">{{ item.name || "" }}</span>
                <span class="txts-time">{{ item.time ? item.time.substring(11, 16) + "-" + item.time.substring(31, 36) : "" }}</span>
              </div>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import enfryComponents from "enfry-component";

const { datePickerInit } = enfryComponents.$tools;
export default {
  name: "viewsCalender",
  props: {
    // 是否需要头部
    needHead: {
      type: Boolean,
      default: true
    },
    // 是否需要整个数据（包含上下月）
    otherMonth: {
      type: Boolean,
      default: true
    },
    // 每个礼拜的起始天，0是礼拜天，1是礼拜一
    startDay: {
      type: [Number, String],
      default: () => (this.startDay === 1 ? 1 : 0)
    },
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      list: [],
      // 当前时间戳
      currentTimeStamp: "",
      // 当前年
      currentYear: null,
      // 当前月
      currentMonth: null,
      // 当前天
      currentDate: null,
      // 我的申请 ，他人申请 在柜 的显示class
      classlis: { 1: "me", 2: "he", 3: "in" },
      cliclItemdata: {},
      left: 0,
      top: 0,
      goodsStatus: {
        "": "",
        1: "新增中",
        2: "新增失败",
        3: "在柜",
        4: "离柜"
      },
      userName: this.$store.getters.userInfo.name
    };
  },
  computed: {
    // 此处由于日历面板的第一天位置需要动态变幻
    weekArr() {
      if (parseInt(this.startDay, 10) === 1) {
        return ["一", "二", "三", "四", "五", "六", "日"];
      }
      return ["日", "一", "二", "三", "四", "五", "六"];
    }
  },
  mounted() {
    this.getDateInfo();
  },
  methods: {
    // 获取时间
    getDateInfo() {
      const d = new Date();
      this.currentYear = d.getFullYear();
      this.currentMonth = d.getMonth() + 1;
      this.currentDate = d.getDate();
      this.getListData();
      this.getTimeStamp();
    },
    /**
     * @description 获取列表数据
     */
    getListData() {
      if (this.otherMonth) {
        this.list = datePickerInit.getWholeMonthData(this.currentYear, this.currentMonth, parseInt(this.startDay, 10));
      } else {
        this.list = datePickerInit.getMonthData(this.currentYear, this.currentMonth, parseInt(this.startDay, 10));
      }
      this.toParent();
    },
    /**
     * @description 获取当前天的时间戳
     */
    getTimeStamp() {
      this.currentTimeStamp = datePickerInit.getTimeStamp(this.currentYear, this.currentMonth, this.currentDate);
    },
    /**
     * @description 月份切换
     * @param type 1为下个月，-1为上个月
     */
    changeMonth(type) {
      if (type === 1) {
        if (this.currentMonth === 12) {
          this.currentMonth = 1;
          this.currentYear++;
        } else {
          this.currentMonth++;
        }
      } else if (this.currentMonth === 1) {
        this.currentMonth = 12;
        this.currentYear--;
      } else {
        this.currentMonth--;
      }
      this.getListData();
    },
    // 判断 显示的class 自己申请 他人申请 已结束
    getClass(item) {
      let str = "";
      if (item.billStatus === "003") {
        // 已结束
        str = "end";
      } else if (this.userName === item.createName) {
        str = "mine";
      } else {
        str = "he";
      }
      return str;
    },
    // 向父级返回日期 格式2020-02
    toParent() {
      const date = `${this.currentYear}-${Number(this.currentMonth) < 10 ? `0${this.currentMonth}` : this.currentMonth}`;
      this.$emit("toParent", date);
    },
    // 判断 2个 对象是否相等
    isequals(x, y) {
      const in1 = x instanceof Object;
      const in2 = y instanceof Object;
      if (!in1 || !in2) {
        return x === y;
      }
      if (Object.keys(x).length !== Object.keys(y).length) {
        return false;
      }
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const p in x) {
        const a = x[p] instanceof Object;
        const b = y[p] instanceof Object;
        if (a && b) {
          return this.equals(x[p], y[p]);
        }
        if (x[p] !== y[p]) {
          return false;
        }
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.en-date-picker {
  position: relative;
  width: 100%;
  border: 1px solid #e8ecf2;

  .en-date-picker-header {
    display: flex;
    justify-content: flex-start;
    line-height: 44px;
    font-size: 12px;
    background-color: #f7f9f9;
    color: #636c78;
    border-bottom: 1px solid #e8ecf2;
    cursor: pointer;

    .en-icon {
      width: 12px;
      height: 12px;
      margin: 16px 5px;

      &:nth-last-child(1) {
        transform: rotate(180deg);
      }
    }
  }

  .en-date-picker-week {
    display: flex;
    justify-content: flex-start;
    line-height: 48px;
    background-color: #f6fafd;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    color: #333333;
    font-size: 12px;
    position: relative;
    z-index: 2;

    .en-date-picker-week-item {
      width: calc(100% / 7);
      text-align: center;
      border-right: 1px solid #e8ecf2;

      &:nth-last-child(1) {
        border-right: none;
      }
    }
  }

  .en-date-picker-body {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .en-date-picker-item {
      height: 130px;
      position: relative;
      width: calc(100% / 7);
      border-right: 1px solid #e8ecf2;
      border-top: 1px solid #e8ecf2;
      cursor: pointer;

      &:hover {
        background-color: #f5f9fd;
      }

      /* 每行最后一个单元格的样式 */
      &:nth-child(7n) {
        border-right: none;
      }

      .label {
        position: absolute;
        right: 14px;
        top: 7px;
        width: 24px;
        height: 24px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        color: #333333;
        line-height: 24px;
        font-size: 12px;
        text-align: center;
      }

      /* 当前天样式 */
      &.current-date {
        .label {
          background-color: #3e90fe;
          color: #ffffff;
          border-radius: 50%;
        }
      }

      /* 其他月份样式 */
      &.other-month {
        .label {
          color: #cdcdcd;
        }
      }
      // 内部信息
      .col {
        width: 100%;
        height: 90px;
        position: absolute;
        overflow: auto;
        top: 37px;
        .col-names {
          cursor: pointer;
          font-family: Microsoft YaHei;
          margin-bottom: 1px;
          height: 26px;
          font-size: 12px;
          line-height: 26px;
          padding: 0 8px;
          position: relative;
          .txts-div {
            height: 26px;
            .txts-event {
              float: left;
            }
            .txts-time {
              float: right;
            }
            &:after {
              clear: both;
            }
          }
        }
        // 他人申请
        .col-names.he {
          background: #fdefef;
          border: 1px solid rgba(247, 107, 107, 0.2);
          border-left: 2px solid #f76b6b;
          color: #f76b6b;
        }
        // 已结束
        .col-names.end {
          background: #efeff1;
          border: 1px solid rgba(99, 107, 119, 0.2);
          border-left: 2px solid #636b77;
          // color: #636B77;
          color: rgba(99, 107, 119, 0.5);
          .txts-event {
            text-decoration: line-through;
          }
        }
        // 自己申请
        .col-names.mine {
          background: rgba(236, 244, 252, 1);
          border: 1px solid rgba(70, 148, 223, 0.2);
          border-left: 2px solid rgba(70, 148, 223, 1);
          color: rgba(70, 148, 223, 1);
        }
      }
    }
  }
}
// 点击 弹出
.cardinfo-alert {
  height: 116px;
  background: #fff;
  padding: 12px;
  .col-names {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    height: 12px;
    // background: chocolate;
    line-height: 12px;
    margin-bottom: 10px;
  }
  .col {
    height: 24px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636c78;
    line-height: 24px;
    display: flex;
    align-items: center;
  }
}
</style>
