var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("en-select", {
    staticStyle: { display: "inline-block" },
    attrs: { placeholder: "请选择", data: _vm.options },
    on: { change: _vm.viewsSwitching },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }